export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const LOGIN_USER = 'LOGIN_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const PROCESS_ACTIVATION = 'PROCESS_ACTIVATION';
export const SET_INFORMATION = 'SET_INFORMATION';
export const SET_ADDRESS_DETAIL_ACTION = 'SET_ADDRESS_DETAIL_ACTION';
export const SET_BUSINESS_DETAIL_ACTION = 'SET_BUSINESS_DETAIL_ACTION';
export const SET_CELL_INFO_ACTION = 'SET_CELL_INFO_ACTION';
export const SET_COMPLETE_ACTIVATION_ACTION = 'SET_COMPLETE_ACTIVATION_ACTION';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_STEP_2 = 'FORGOT_PASSWORD_STEP_2';
export const FORGOT_PASSWORD_DATA = 'FORGOT_PASSWORD_DATA';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SAVE_SUB_USERS = "export const";
export const REGISTER_STEP_2_USER = 'REGISTER_STEP_2_USER';
export const RESEND_ACTIVATE_EMAIL = 'RESEND_ACTIVATE_EMAIL';
export const USER_LOGGEDIN = 'USER_LOGGEDIN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const USER_LOGGEDOUT = 'USER_LOGGEDOUT';
export const SET_GLOBALALERT = 'SET_GLOBALALERT';
export const RESET_GLOBALALERT = 'RESET_GLOBALALERT';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const RESET_SNACKBAR = 'RESET_SNACKBAR';
export const LOAD_MEMBER_INFO= 'LOAD_MEMBER_INFO';
export const UPDATE_MEMBER_INFO = 'UPDATE_MEMBER_INFO';
export const GET_CREDIT_CARD_DETAILS = "GET_CREDIT_CARD_DETAILS";
export const SAVE_CREDIT_CARD_DETAILS = "SAVE_CREDIT_CARD_DETAILS";
export const UPDATE_CARD_DETAILS = "UPDATE_CARD_DETAILS";
export const REMOVE_CARD_DETAILS = "REMOVE_CARD_DETAILS";
export const LOAD_CREDIT_CARD_DETAILS = "LOAD_CREDIT_CARD_DETAILS";
export const SET_SECURITY = "SET_SECURITY";
export const SET_COMMUNICATION_PREFERENCES = "SET_COMMUNICATION_PREFERENCES";
export const SET_MANAGE_APPS ="SET_MANAGE_APPS";
export const VERIFIED_OTP_ACTION = "VERIFIED_OTP_ACTION";
export const SAVE_SUB_USER_TYPE ="SAVE_SUB_USER_TYPE";
export const EMAIL_VERIFICATION ="EMAIL_VERIFICATION";
export const SET_CONFIRM_DIALOG ="SET_CONFIRM_DIALOG";
export const RESET_CONFIRM_DIALOG ="RESET_CONFIRM_DIALOG";
export const SET_IMPORT_METHOD = "SET_IMPORT_METHOD";
export const SET_GROUPS = "SET_GROUPS";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const SAVE_GROUP = "SAVE_GROUP";
export const SET_FILE_PATH = "SET_FILE_PATH";
export const IMPORT_CONTACT = "IMPORT_CONTACT";
export const SET_IMPORT_CONTACT_RESULT = "SET_IMPORT_CONTACT_RESULT";
export const UPLOAD_HEADERS = "UPLOAD_HEADERS";
export const FETCH_GROUP_INFORMATION = "FETCH_GROUP_INFORMATION";
export const SET_GROUP_INFORMATION = "SET_GROUP_INFORMATION";
export const SET_IMPORT_TYPE = "SET_IMPORT_TYPE";
export const SET_HEADERS = "SET_HEADERS";
export const SET_UDFS = "SET_UDFS";
export const SET_GROUP_ID = "SET_GROUP_ID";
export const RESET_STORE = "RESET_STORE";
export const CRON_CHECK_DUPLICATE_YN = "CRON_CHECK_DUPLICATE_YN";
export const SET_SEGMENT_POPUP = "SET_SEGMENT_POPUP";
export const FETCH_GROUP_UDF_LIST = "FETCH_GROUP_UDF_LIST";
export const SET_GROUP_UDF_LIST = "SET_GROUP_UDF_LIST";
export const SET_EDIT_SEGMENT_POPUP = "SET_EDIT_SEGMENT_POPUP";
export const GET_SEGMENT_DETAILS = "GET_SEGMENT_DETAILS";
export const SET_SEGMENT_DETAILS = "SET_SEGMENT_DETAILS";
export const SET_DUPLICATE_SEGMENT_POPUP = "SET_DUPLICATE_SEGMENT_POPUP";
export const SET_PENDING_TRANSACTION = "SET_PENDING_TRANSACTION";
export const RESET_PENDING_TRANSACTION = "RESET_PENDING_TRANSACTION";
export const SET_SUB_USER = "SET_SUB_USER";
export const RESET_SUB_USER = "RESET_SUB_USER";
export const SET_MENU_LIST = "SET_MENU_LIST";
export const RESET_MENU_LIST = "RESET_MENU_LIST";
export const SET_MODULE_LIST = "SET_MODULE_LIST";
export const RESET_MODULE_LIST = "RESET_MODULE_LIST";
export const SET_COUNTRY_SETTING = "SET_COUNTRY_SETTING";
export const RESET_COUNTRY_SETTING = "RESET_COUNTRY_SETTING";
export const SET_LOADER = "SET_LOADER";